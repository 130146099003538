import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { UserIcon } from '@heroicons/react/24/solid';
import { CircularProgress } from '@mui/material'; 
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'; 
import { Link } from 'react-router-dom';  // Importer Link pour la navigation
import UserCredits from './UserCredits';

const CvUploader = () => {
    const [file, setFile] = useState(null);
    const [feedback, setFeedback] = useState('');  // Retours de l'IA
    const [score, setScore] = useState(null);  // Note
    const [error, setError] = useState('');
    const [uploading, setUploading] = useState(false);
    const [analysisComplete, setAnalysisComplete] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const [insufficientCredits, setInsufficientCredits] = useState(false);  // Ajout pour gérer les crédits insuffisants
    const [reloadCredits, setReloadCredits] = useState(false);  // État pour recharger les crédits
    const token = useSelector((state) => state.auth.token);
    const userId = useSelector((state) => state.auth.user.id);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            if (selectedFile.size > 5000000) { // Limite de taille de fichier: 5MB
                setError('Le fichier dépasse la taille maximale de 5 Mo.');
                setFile(null);
            } else if (!['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(selectedFile.type)) {
                setError('Seuls les fichiers PDF et DOCX sont autorisés.');
                setFile(null);
            } else {
                setError('');
                setFile(selectedFile);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            setError('Veuillez sélectionner un fichier.');
            return;
        }
        setError('');
        setFeedback('');
        setScore(null);  // Réinitialiser la note à chaque nouvelle soumission
        setUploading(true);
        setUploadSuccess(false);
        setUploadError(false);
        setAnalysisComplete(false);
        setInsufficientCredits(false);  // Réinitialiser cet état

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch('https://cvanalyzerback.osc-fr1.scalingo.io/api/cv/upload', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-User-Id': userId,
                },
                body: formData
            });

            const result = await response.json();
            if (!response.ok) {
                if (result.error === "Crédits insuffisants pour effectuer cette action") {
                    setInsufficientCredits(true);
                    throw new Error(result.error);
                }
                throw new Error('Erreur lors du téléchargement du CV.');
            }

            setFeedback(result.feedback);  // Feedback
            setScore(result.score !== undefined ? result.score : 0);  // Note
            setUploadSuccess(true);
            setAnalysisComplete(true);
            setReloadCredits(true);  // Déclencher la mise à jour des crédits après un succès
        } catch (error) {
            setError(error.message);
            setUploadError(true);
        } finally {
            setUploading(false);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-4">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md transition-transform transform hover:scale-105">
                <div className="flex flex-col items-center mb-6">
                    <UserIcon className="w-16 h-16 text-blue-500" />
                    <div className="mt-2">
                        <UserCredits reload={reloadCredits} />  {/* Passer l'état reload */}
                    </div>
                </div>
                <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Téléchargez votre CV</h1>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <input 
                        type="file" 
                        onChange={handleFileChange} 
                        accept=".pdf,.docx" 
                        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                    />
                    {error && <p className="text-red-500 mt-2 text-center">{error}</p>}
                    <button 
                        type="submit" 
                        className={`w-full mt-4 p-2 rounded-lg shadow transition duration-300 ${uploading ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-600'} text-white`}
                        disabled={uploading}
                    >
                        {uploading ? (
                            <div className="flex items-center justify-center">
                                <CircularProgress size={24} className="mr-2" />
                                Analyse en cours...
                            </div>
                        ) : (
                            'Télécharger'
                        )}
                    </button>
                </form>

                {/* Afficher un message si les crédits sont insuffisants */}
                {insufficientCredits && (
                    <div className="mt-6 bg-yellow-100 p-4 rounded-lg shadow-md">
                        <h2 className="text-2xl font-bold text-yellow-700 mb-4">Crédits insuffisants</h2>
                        <p className="text-gray-600 text-center">
                            Vous n'avez pas assez de crédits pour analyser votre CV. Veuillez{' '}
                            <Link to="/subscription" className="text-blue-600 underline">
                                recharger votre compte
                            </Link>{' '}
                            pour continuer.
                        </p>
                    </div>
                )}

                {uploadSuccess && !analysisComplete && (
                    <div className="mt-6 bg-white p-6 rounded-lg shadow-md">
                        <div className="flex items-center text-blue-500 mb-4">
                            <CircularProgress size={24} className="mr-2" />
                            <h2 className="text-2xl font-bold">Analyse de votre CV en cours...</h2>
                        </div>
                        <p className="text-gray-600 text-center">Veuillez patienter pendant que notre IA analyse votre CV et génère des suggestions.</p>
                    </div>
                )}
                {analysisComplete && (
                    <div className={`mt-6 bg-white p-6 rounded-lg shadow-md ${uploadSuccess ? 'border-l-4 border-green-500' : 'border-l-4 border-red-500'}`}>
                        <div className="flex items-center mb-4">
                            {uploadSuccess ? (
                                <CheckCircleIcon className="w-6 h-6 text-green-500 mr-2" />
                            ) : (
                                <XCircleIcon className="w-6 h-6 text-red-500 mr-2" />
                            )}
                            <h2 className="text-2xl font-bold">{uploadSuccess ? 'Suggestions d\'amélioration' : 'Erreur de traitement'}</h2>
                        </div>
                        <div className="bg-gray-100 p-4 rounded text-sm overflow-auto max-h-60">
                            <pre className="whitespace-pre-wrap">{feedback}</pre>
                        </div>
                        {score !== null && (
                            <div className="mt-4">
                                <h3 className="text-xl font-semibold text-gray-800">Note de l'IA : {score}/100</h3>
                                <div className="w-full bg-gray-200 rounded-full h-4 mt-2">
                                    <div className="bg-green-500 h-4 rounded-full" style={{ width: `${score}%` }}></div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {uploadError && (
                    <div className="mt-6 bg-white p-6 rounded-lg shadow-md border-l-4 border-red-500">
                        <div className="flex items-center mb-4 text-red-500">
                            <XCircleIcon className="w-6 h-6 mr-2" />
                            <h2 className="text-2xl font-bold">Erreur de téléchargement</h2>
                        </div>
                        <pre className="bg-gray-100 p-4 rounded text-sm whitespace-pre-wrap">{feedback}</pre>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CvUploader;
